
<template>
    <div :class="['input textarea', inputClassName, {'active' : modelValue || value}, errors?.$errors[0]?.$message ? 'error' : '']">
        <label :for="id" :class="labelClassName" v-html="label"></label>
    <textarea
        :id="id"
        v-mask="mask"
        :class="className"
        :placeholder="placeholder"
        :name="name"
        :value="internalValue"
        @input="onInput"
        ref="textarea"></textarea>
    <span v-if="errors" :class="errorClass ?? 'form-error'" v-show="errors.$dirty">{{ errors.$errors[0]?.$message }}</span>
    </div>
</template>

<script>
import VueMask from 'v-mask';
import { commonProps } from '../../props.js';

export default {
    props: {
        inputClassName: {
            type: String,
            default: ''
        },
        ...commonProps
    },
    emits: ['update:modelValue'],
    components: {
        VueMask
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    mounted() {
        this.resizeTextarea();
    },
    methods: {
        onInput(event) {
            this.internalValue = event.target.value;
            this.resizeTextarea()
        },
        resizeTextarea() {
            const textarea = this.$refs.textarea;
            textarea.style.height = '0px';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }
}
</script>
