<template>
    <a @click="openForm" class="underlined green" target="_blank">{{this.title}}</a>
</template>

<script>
export default {
    name: 'BtnOpenIframe',
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        },
        year: {
            type: String,
            default: ''
        }
    },
    methods: {
        openForm() {
            this.$store.commit('toggleModalIframeShow');
            this.$store.commit('setActiveIframe', {
                title: this.title,
                description: this.description,
                link: this.link,
                year: this.year,
                tags: this.tags,
            });
        }
    },
}
</script>

