document.addEventListener('DOMContentLoaded', () => {
    const cookies = document.querySelector('.cookies');
    const btnClose = document.querySelector('.btn-close');
    if (!localStorage.getItem('cookies_accepted')) {
        cookies.classList.remove('hidden');
    } else {
        cookies.style.display = 'none';
    }
    btnClose.addEventListener('click', () => {
        cookies.classList.add('hidden');
        setTimeout(() => {
            cookies.style.display = 'none';
        }, 300);
        localStorage.setItem('cookies_accepted', 'true');
    });
});
