<template>
    <transition>
        <div class="modal modal-iframe" v-if="isOpen">
            <div class="modal-layout" @click="closeModal"></div>
            <div class="modal-wrapper container">
                <div class="info">
                    <div class="info-top">
                        <div class="top">
                            <h4>{{ activeIframe.title }}</h4>
                            <button class="modal-close" @click="closeModal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18" stroke="#676565" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6 6L18 18" stroke="#676565" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div v-html="activeIframe.description" class="desc"></div>
                    </div>
                    <div :class="{ 'desc-layout mobile': true, open: isMobileDescOpen }"  @click="toggleMobileDesc"></div>
                    <div :class="{ 'info-bottom': true, open: isMobileDescOpen }">
                        <div class="desc mobile" v-html="activeIframe.description"></div>
                        <div class="tags">
                            <span v-for="(tag, index) in activeIframe.tags" :key="index">
                                {{ tag }}<span v-if="!isLastTag(index)">, </span>
                            </span>
                        </div>
                        <span v-if="activeIframe.year" class="lead year">{{ activeIframe.year }}</span>
                    </div>
                    <div class="show-desc-btn mobile">
                        <span class="underlined green" @click="toggleMobileDesc">{{ toggleButtonText }}</span>
                    </div>
                </div>
                <LoaderForm v-if="isIframeLoading" />
                <div class="iframe-wrapper" v-show="!isIframeLoading">
                    <iframe :src="activeIframe.link" @load="onIframeLoad"></iframe>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import LoaderForm from './LoaderForm.vue';

export default {
    name: 'ModalIframe',
    components: {
        LoaderForm,
    },
    data() {
        return {
            isMobileDescOpen: false,
            isIframeLoading: true,
        };
    },
    computed: {
        isOpen() {
            return this.$store.state.modalIframeOpen;
        },
        activeIframe() {
            return this.$store.state.activeIframe;
        },
        toggleButtonText() {
            return this.isMobileDescOpen ? 'Скрыть описание' : 'Показать описание кейса';
        }
    },
    methods: {
        closeModal() {
            this.$store.commit('toggleModalIframeShow');
        },
        isLastTag(index) {
            return index === this.activeIframe.tags.length - 1;
        },
        toggleMobileDesc() {
            this.isMobileDescOpen = !this.isMobileDescOpen;
        },
        onIframeLoad() {
            this.isIframeLoading = false;
        }
    },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
