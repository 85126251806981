<template>
    <transition>
        <div class="modal modal-result" v-if="isOpen" :class="{ fail: modalResultType === 'fail' }">
            <div class="modal-layout" @click="closeModal"></div>
            <div class="modal-wrapper container">
                <button class="modal-close" @click="closeModal">
                    <svg v-if="modalResultType === 'success'" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="44" height="44" rx="22" fill="#7DAA8F" fill-opacity="0.2"/>
                        <path d="M28 16L16 28" stroke="#417957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 16L28 28" stroke="#417957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="44" height="44" rx="22" fill="#E8A9A0" fill-opacity="0.2"/>
                        <path d="M28 16L16 28" stroke="#94594C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 16L28 28" stroke="#94594C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="modal-content">
                    <h4 v-html="modalResultType === 'success' ? 'Спасибо! <br> Форма&nbsp;отправлена' : 'Ошибка'"></h4>
                    <p v-if="modalResultType === 'fail'">Извините, произошла ошибка</p>
                    <button @click="closeModal" class="lead underlined">
                        Вернуться
                    </button>
                </div>
                <svg id="svg-success" v-if="modalResultType === 'success'" width="244" height="242" viewBox="0 0 244 242" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M174.103 128.698C167.573 117.924 144.885 104.593 126.436 117.754M47.0045 99.8449C58.3265 92.1752 80.6053 95.097 89.0137 111.248M154.381 166.4C133.563 218.177 42.2566 193.342 49.3162 138.546C49.3162 138.546 69.2833 148.256 97.7709 155.809C126.258 163.361 154.381 166.4 154.381 166.4ZM10.4407 115.101C4.53325 182.983 58.9051 231.171 120.384 231.986C181.862 232.802 223.76 197.148 232.945 134.464C242.131 71.7796 189.902 15.465 131.833 10.4116C73.7633 5.35819 16.3481 47.2188 10.4407 115.101Z" stroke="#D8EDE0" stroke-width="20"/>
                </svg>
                <svg v-else id="svg-error" width="244" height="242" viewBox="0 0 244 242" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M190.047 127.282C180.977 136.024 157.055 148.314 133.935 127.531M41.1528 119.457C50.6177 127.246 74.8569 139.107 96.0942 124.238M126.904 195.533C115.947 187.742 85.2679 170.698 50.2062 164.855M10.4407 115.101C4.53327 182.983 58.9051 231.171 120.384 231.986C181.862 232.802 223.76 197.148 232.945 134.464C242.131 71.7796 189.902 15.465 131.833 10.4116C73.7634 5.35819 16.3481 47.2188 10.4407 115.101Z" stroke="#FFE1DB" stroke-width="20"/>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalResult',
    computed: {
        isOpen() {
            return this.$store.state.modalResultOpen;
        },
        modalResultType() {
            return this.$store.state.modalResultType;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit('toggleResultModalShow');
        },
    },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
