import { gsap } from "gsap";

document.addEventListener('DOMContentLoaded', () => {
    const svgItems = document.querySelectorAll('.svg-animate');

    svgItems.forEach(svg => {
        const paths = svg.querySelectorAll('path');

        paths.forEach(path => {
            const pathLength = path.getTotalLength();

            gsap.set(path, {
                strokeDasharray: pathLength,
                strokeDashoffset: pathLength
            });

            const animateSvg = () => {
                gsap.to(path, {
                    strokeDashoffset: 0,
                    duration: 4,
                    ease: 'power1.in'
                });
            };

            const animateSvgSlow = () => {
                gsap.to(path, {
                    strokeDashoffset: 0,
                    duration: 40,
                    ease: 'power2.out'
                });
            };

            const animateSvgFast = () => {
                gsap.to(path, {
                    strokeDashoffset: 0,
                    duration: 1,
                    ease: 'power2.out'
                });
            };

            let observerOptions = {
                root: null,
                rootMargin: '0px',
                threshold: svg.id === 'svg-zigzag' || 'svg-spiral-red' ? 0.005 : 0.1
            };

            const observerCallback = (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (svg.id === 'svg-asterisk' || svg.id === 'svg-triangles') {
                            animateSvgSlow();
                        } else {
                            animateSvg();
                        }
                        observer.unobserve(svg);
                    }
                });
            };

            const observer = new IntersectionObserver(observerCallback, observerOptions);
            observer.observe(svg);
        });
    });
});
