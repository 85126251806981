const toggleModalShow = (state) => {
    state.modalOpen = !state.modalOpen;
    if (state.modalOpen) {
        document.documentElement.style.overflow = 'hidden';
    } else {
        document.documentElement.style.overflow = 'auto';
    }
}
const toggleResultModalShow = (state) => {
    state.modalResultOpen = !state.modalResultOpen;
    if (state.modalResultOpen) {
        document.documentElement.style.overflow = 'hidden';
    } else {
        document.documentElement.style.overflow = 'auto';
    }
}
const setModalContent = (state, payload) => {
    state.modalContent = payload;
}

const setModalResultType = (state, payload) => {
    state.modalResultType = payload;
}

const toggleModalIframeShow = (state) => {
    state.modalIframeOpen = !state.modalIframeOpen;
    if (state.modalIframeOpen) {
        document.documentElement.style.overflow = 'hidden';
    } else {
        document.documentElement.style.overflow = 'auto';
    }
}

const setActiveIframe = (state, payload) => {
    state.activeIframe = payload;
}

export default {
    toggleModalShow,
    setModalContent,
    toggleResultModalShow,
    setModalResultType,
    toggleModalIframeShow,
    setActiveIframe
}
