<template>
    <div :class="['input', inputClassName, {'active' : modelValue || value}, errors?.$errors[0]?.$message ? 'error' : '']">
        <label :for="id" :class="labelClassName" v-html="label"></label>
        <input v-if="type !== 'checkbox'" :id="id" v-mask="mask" :class="[className]" :type="type" :placeholder="placeholder" :name="name" :value="internalValue" @input="onInput" @focus="focusFunction">
        <input v-else :id="id" v-mask="mask" :class="className" :type="type" :placeholder="placeholder" :name="name" :checked="internalValue" @change="onCheckboxChange">
        <span v-if="errors" :class="errorClass ?? 'form-error'" v-show="errors.$dirty">{{ errors.$errors[0]?.$message }}</span>
    </div>
</template>

<script>
import VueMask from 'v-mask';
import { commonProps } from '../../props.js';
export default {
    props: {
        inputClassName: {
            type: String,
            default: ''
        },
        ...commonProps
    },
    emits: ['update:modelValue'],
    components: {
        VueMask
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue ?? this.value;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        onInput(event) {
            this.internalValue = event.target.value ?? this.value;
        },
        onCheckboxChange(event) {
            this.internalValue = event.target.checked;
        }
    }
}
</script>

