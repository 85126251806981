document.addEventListener('DOMContentLoaded', function() {
    if (document.querySelector('.faq')) {
        document.querySelectorAll('.question').forEach(question => {
            question.addEventListener('click', function() {
                this.closest('.faq-item').classList.toggle('open');
            });
        });
    }
});

