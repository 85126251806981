<template>
    <a @click="openForm">
        Стать клиентом
    </a>
</template>

<script>

export default {
    name: 'LinkOpenForm',
    methods: {
        openForm() {
            this.$store.commit('toggleModalShow');
        }
    },
}
</script>

