import lozad from 'lozad';
document.addEventListener("DOMContentLoaded", function () {
    const observer = lozad('.lozad', {
        load: function (el) {
            if (el.hasAttribute('data-src')) {
                if (el.tagName === 'VIDEO') {
                    el.src = el.getAttribute('data-src');
                    el.load();
                } else {
                    el.src = el.getAttribute('data-src');
                    el.onload = () => {
                        el.classList.remove('lozad');
                        el.parentNode.classList.remove('lozad');
                    }
                }
            }
        },
        loaded: function (el) {
            if (el.tagName === 'VIDEO') {
                el.classList.remove('lozad');
                el.parentNode.classList.remove('lozad');
            }
            if (el.hasAttribute('data-background')) {
                let img = new Image();
                img.src = el.getAttribute('data-background');
                img.onload = () => {
                    el.style.backgroundImage = `url(${img.src})`;
                    el.classList.remove('lozad');
                    el.parentNode.classList.remove('lozad');
                }
            }
        }
    });
    observer.observe();
});
