<template>
    <transition>
        <div class="modal" v-if="isOpen">
            <div class="modal-layout" @click="closeModal"></div>
            <div class="modal-wrapper container">
                <button class="modal-close" @click="closeModal">
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="44" height="44" rx="22" fill="#E1E2E4" fill-opacity="0.2"/>
                        <path d="M28 16L16 28" stroke="#676565" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 16L28 28" stroke="#676565" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="modal-content">
                    <h4>Оставьте заявку, и мы свяжемся с вами в ближайшее время</h4>
                    <FormBuilder :name="'contact_us_3'"></FormBuilder>
                </div>
                <svg id="svg-chat" width="857" height="654" viewBox="0 0 857 654" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M428.5 32C163.298 32 32 129.035 32 248.735C32 322.861 84.9498 388.296 186.642 427.376L132.408 572L305.372 457.156C342.758 462.57 383.831 465.469 428.5 465.469C686.928 465.469 825 368.434 825 248.735C825 133.673 686.607 3.65 371.152 108.95" stroke="#E1E2E4" stroke-width="64"/>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
import FormBuilder from "./FormBuilder.vue";

export default {
    name: 'ModalForm',
    components: { FormBuilder },
    computed: {
        isOpen() {
            return this.$store.state.modalOpen;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit('toggleModalShow');
        },
    },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
