import {createApp} from 'vue/dist/vue.esm-bundler';
import {store} from "./store/store.js";
import FormBuilder from "./components/FormBuilder.vue";
import { VueMaskDirective } from 'v-mask';
import ScreenGenerator from "./components/ScreenGenerator.vue";
import ModalForm from "./components/ModalForm.vue";
import BtnOpenForm from "./components/BtnOpenForm.vue";
import LinkOpenForm from "./components/LinkOpenForm.vue";
import ModalResult from "./components/ModalResult.vue";
import ModalIframe from "./components/ModalIframe.vue";
import BtnOpenIframe from "./components/BtnOpenIframe.vue";
import ImgOpenIframe from "./components/ImgOpenIframe.vue";

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

if (document.getElementById("app") !== null) {
    const app = createApp({})
        .component('form-builder', FormBuilder)
        .directive('mask', vMaskV3)
        .use(store)
        .mount('#app')
}

if (document.getElementById("app_2") !== null) {
    const app = createApp({})
        .component('form-builder', FormBuilder)
        .directive('mask', vMaskV3)
        .use(store)
        .mount('#app_2')
}

if (document.getElementById("app_3") !== null) {
    const app = createApp({})
        .component('form-builder', FormBuilder)
        .directive('mask', vMaskV3)
        .use(store)
        .mount('#app_3')
}

if (document.getElementById("app_4") !== null) {
    const app = createApp({})
        .component('form-builder', FormBuilder)
        .directive('mask', vMaskV3)
        .use(store)
        .mount('#app_4')
}

createApp(ModalForm).use(store).directive('mask', vMaskV3).mount('#modal-form');
createApp(ModalResult).use(store).mount('#modal-result');
createApp(ModalIframe).use(store).mount('#modal-iframe');

if (document.getElementById('btn-open-form')) {
    const app = createApp({})
        .component('btn-open-form', BtnOpenForm)
        .use(store)
        .mount('#btn-open-form')
}

if (document.getElementById('start-link')) {
    const app = createApp({})
        .component('btn-open-form', BtnOpenForm)
        .use(store)
        .mount('#start-link')
}

if (document.getElementById('start-link-2')) {
    const app = createApp({})
        .component('btn-open-form', BtnOpenForm)
        .use(store)
        .mount('#start-link-2')
}

if (document.getElementById('link-open-form')) {
    const app = createApp({})
        .component('link-open-form', LinkOpenForm)
        .use(store)
        .mount('#link-open-form')
}

if (document.getElementById("screen-generator") !== null) {
    const generator = createApp({})
        .component('screen-generator', ScreenGenerator)
        .use(store)
        .mount('#screen-generator')
}

const bannerTabs = document.querySelectorAll('.banner .tabs .tab')
if(bannerTabs){
    bannerTabs.forEach((tab) => {
        tab.addEventListener('click', () => {
            store.commit('toggleModalShow');
        })
    })
}


if (document.querySelectorAll('.client-block .info h4').length > 0) {
    const clientBlocks = document.querySelectorAll('.client-block .info h4');
    clientBlocks.forEach((block) => {
        createApp({})
            .component('btn-open-iframe', BtnOpenIframe)
            .use(store)
            .mount(block);
    });
}

if (document.querySelectorAll('.client-block .img-wrapper').length > 0) {
    const clientBlocks = document.querySelectorAll('.client-block .img-wrapper');
    clientBlocks.forEach((block) => {
        createApp({})
            .component('img-open-iframe', ImgOpenIframe)
            .use(store)
            .mount(block);
    });
}

/**
 * Добавляем CSRF-защиту
 */
let el = document.querySelector('meta[name="csrf-token"]');
let token = el
    ? el.getAttribute('content')
    : null;
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
