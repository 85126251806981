<template>
    <button @click="openForm" class="btn"><span class="lead">Начать проект с Rocont</span></button>
</template>

<script>

export default {
    name: 'BtnOpenForm',
    methods: {
        openForm() {
            this.$store.commit('toggleModalShow');
        }
    },
}
</script>

