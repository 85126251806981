import {stateModal} from "./state/stateModal";

export const state = {
    ...stateModal,
    formResultOpen: false,
    formResultType: 'success',
    formResultName: null,
    activeIframe: {
        title: '',
        description: '',
        link: '',
        year: '',
        tags: []
    },
}
