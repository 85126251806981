import Swiper from "swiper";
import { Navigation, Pagination, Scrollbar, Thumbs, Autoplay } from "swiper/modules";
Swiper.use([Navigation, Pagination, Scrollbar, Thumbs, Autoplay]);

const swiperBrands = new Swiper('.swiper-brands', {
    spaceBetween: 8,
    slidesPerView: 'auto',
    autoplay: {
        delay: 0,
    },
    speed: 2000,
    loop: true,
    breakpoints: {
        768: {
            spaceBetween: 16,
        },
    },
});

const swiperWork = new Swiper('.swiper-work', {
    spaceBetween: 8,
    slidesPerView: 'auto',
    breakpoints: {
        1440: {
            spaceBetween: 32,
        },
        768: {
            spaceBetween: 16,
        },
    },
});

let swiperOneMore = null;
function initSwiperOneMore() {
    if (window.innerWidth < 768 && !swiperOneMore) {
        swiperOneMore = new Swiper('.swiper-one-more', {
            spaceBetween: 8,
            slidesPerView: 'auto',
        });
    } else if (window.innerWidth >= 768 && swiperOneMore) {
        swiperOneMore.destroy(true, true);
        swiperOneMore = null;
    }
}

window.addEventListener('load', initSwiperOneMore);
window.addEventListener('resize', initSwiperOneMore);
