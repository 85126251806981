<template>
    <div>
        <form @submit.prevent="generate" class="screen-generator">
            <div v-for="(screen, screenIndex) in screens" :key="screenIndex">
                <div class="top-bar" @click="toggleAccordion($event)">
                    <span class="top-bar-title">Screen {{ screenIndex + 1 }} {{ screen.modelName ? '- ' + screen.modelName : ''}}</span>
                    <button type="button" class="remove-btn" v-if="screenIndex !== 0" @click.stop="removeScreen(screenIndex)">x</button>
                </div>
                <div class="input-section">
                    <div v-if="errors[screenIndex]" class="errors">
                        <div v-for="(errorList, field) in errors[screenIndex]" :key="field" class="errors-list">
                            <span v-for="error in errorList" :key="error" class="error-item">{{ error }}</span>
                        </div>
                    </div>
                    <div class="fields-container">
                        <label for="screenType">Screen Type
                            <select id="screenType" v-model="screen.screenType">
                                <option value="list-edit">Screen</option>
                                <option value="list-modal">Modal</option>
                            </select>
                        </label>

                        <label for="modelName">Model Name
                            <input id="modelName" v-model="screen.modelName" placeholder="Model Name">
                        </label>

                        <label for="screenTitle">Screen Title
                            <input id="screenTitle" v-model="screen.screenTitle" placeholder="Screen Title">
                        </label>

                        <label for="menuTitle">Menu Title
                            <input id="menuTitle" v-model="screen.menuTitle" @input="onMenuTitleChange(screenIndex, $event.target.value)" placeholder="Menu Title">
                        </label>
                    </div>

                    <div v-for="(field, index) in screen.fields" :key="index">

                        <div class="top-bar" @click="toggleAccordion($event)">
                            <span class="top-bar-title">Field {{ index + 1 }} {{ field.name ? '- ' + field.name : ''}}</span>
                            <button type="button" class="remove-btn" @click.stop="removeField(screenIndex, index)">x</button>
                        </div>
                        <div class="field-section">
                            <div class="fields-data">
                                <div class="top-side">
                                    <label :for="'is_list-' + screenIndex + index">Is List
                                        <input type="checkbox" :id="'is_list-' + screenIndex + index" v-model="field.is_list">
                                    </label>

                                    <label :for="'is_required-' + screenIndex + index">Is Required
                                        <input type="checkbox" :id="'is_required-' + screenIndex + index" v-model="field.is_required">
                                    </label>
                                </div>
                                <div class="middle-side">
                                    <label :for="'fieldName-' + screenIndex + index">Field Name
                                        <input :id="'fieldName-' + screenIndex + index" v-model="field.name" placeholder="Field Name">
                                    </label>

                                    <label :for="'fieldCode-' + screenIndex + index">Field Code
                                        <input :id="'fieldCode-' + screenIndex + index" v-model="field.code" placeholder="Field Code">
                                    </label>

                                    <label :for="'is_migration-' + screenIndex + index">Add to Migration
                                        <select :id="'is_migration-' + screenIndex + index" v-model="field.is_migration">
                                            <option selected value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>

                                    <template v-if="field.is_migration === 'yes'">
                                        <label :for="'column_type-' + screenIndex + index">Column Type
                                            <select :id="'column_type-' + screenIndex + index" v-model="field.column_type">
                                                <option selected value="string">String</option>
                                                <option value="integer">Integer</option>
                                                <option value="text">Text</option>
                                                <option value="timestamp">Timestamp</option>
                                                <option value="boolean">Boolean</option>
                                                <option value="json">JSON</option>
                                            </select>
                                        </label>
                                    </template>
                                </div>
                                <div class="bottom-side">
                                    <label :for="'field_type-' + screenIndex + index">Field Type
                                        <select :id="'field_type-' + screenIndex + index" v-model="field.field_type">
                                            <option selected value="Input">Input</option>
                                            <option value="Textarea">Textarea</option>
                                            <option value="TinyMCE">TinyMCE</option>
                                            <option value="Checkbox">Checkbox</option>
                                            <option value="Select">Select</option>
                                            <option value="DateTimer">DateTimer</option>
                                            <option value="Cropper">Cropper</option>
                                            <option value="Upload">Upload</option>
                                        </select>
                                    </label>

                                    <template v-if="field.field_type === 'Select'">
                                        <label :for="'select_option_model-' + screenIndex + index">Select Option Model
                                            <input :id="'select_option_model-' + screenIndex + index" v-model="field.select_option_model"
                                                   placeholder="Select Option Model">
                                        </label>

                                        <label :for="'is_multiple-' + screenIndex + index">Is Multiple
                                            <select :id="'is_multiple-' + screenIndex + index" v-model="field.is_multiple">
                                                <option selected value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </label>
                                    </template>
                                    <template v-else-if="field.field_type === 'Upload'">
                                        <label :for="'maxFiles-' + screenIndex + index">Max Files
                                            <input :id="'maxFiles-' + screenIndex + index" v-model="field.maxFiles"
                                                   placeholder="Max Files">
                                        </label>
                                        <label :for="'group-' + screenIndex + index">Group Name
                                            <input :id="'group-' + screenIndex + index" v-model="field.group" placeholder="Group Name">
                                        </label>
                                    </template>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="button" class="add-button" @click="addField(screenIndex)">Add Field</button>
                    <br>

                    <div class="menu-container">
                        <label for="menuPlace">Menu Place
                        <select id="menuPlace" v-model="screen.menuPlace" @change="updateMenuPlaces(screenIndex, $event.target.value)">
                            <option v-for="place in menuPlaces" :value="place.value">{{ place.label }}</option>
                        </select>
                        </label>

                        <label for="menuGroup">Menu Group
                        <select id="menuGroup" v-model="screen.menuGroup">
                            <option value="yes">Yes</option>
                            <option selected value="no">No</option>
                        </select>
                        </label>

                        <label v-if="screen.menuGroup === 'yes'" for="groupMenuTitle">Menu Group
                            <input id="groupMenuTitle" v-model="screen.groupMenuTitle" placeholder="Enter group menu title"/>
                        </label>
                    </div>

                    <label class="need-migration" for="needMigration">Need Migration
                    <select id="needMigration" v-model="screen.needMigration">
                        <option selected value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                    </label>

                    <div v-for="(relation, index) in screen.relations" :key="'relation-' + screenIndex + index">
                        <div class="top-bar" @click="toggleAccordion($event)">
                            <span class="top-bar-title">Relation {{ index + 1 }} {{ relation.type ? '- ' + relation.type : ''}} {{ relation.relatedModel ? '- ' + relation.relatedModel : ''}}</span>
                            <button type="button" class="remove-btn" @click.stop="removeRelation(screenIndex, index)">x</button>
                        </div>
                        <div class="field-section">
                            <div class="fields-data">
                                <div class="middle-side">
                                    <label :for="'relationType-' + screenIndex + index">Relation Type
                                        <select :id="'relationType-' + screenIndex + index" v-model="relation.type">
                                            <option selected value="hasOne">Has One</option>
                                            <option value="hasMany">Has Many</option>
                                            <option value="belongsTo">Belongs To</option>
                                            <option value="belongsToMany">Belongs To Many</option>
                                        </select>
                                    </label>

                                    <label :for="'relatedModel-' + screenIndex + index">Related Model
                                        <input :id="'relatedModel-' + screenIndex + index" v-model="relation.relatedModel"
                                               placeholder="Related Model">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" @click="addRelation(screenIndex)">Add relation</button>
                </div>
            </div>
            <div class="buttons-container">
                <button type="button" @click="addScreen">Add Screen</button>
                <button class="generate-button" type="submit" @click.prevent="generate">Generate</button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "ScreenGenerator",
    data() {
        return {
            screens: [{
                screenType: 'list-edit',
                modelName: '',
                screenTitle: '',
                menuTitle: '',
                fields: [],
                relations: [],
                menuPlace: '',
                menuGroup: 'no',
                needMigration: 'yes',
                currentMenuTitle: null,
            }],
            menuPlaces: [],
            errors: {},
        }
    },
    mounted() {
        this.getMenuPlaces();
    },
    methods: {
        onMenuTitleChange(screenIndex, newValue) {
            // Обновляем menuTitle
            this.screens[screenIndex].menuTitle = newValue;

            if (!this.screens[screenIndex].menuTitle) {
                this.screens[screenIndex].menuPlace = '';
            }

            // Запрашиваем обновленный список menuPlaces с сервера
            this.getMenuPlaces();
        },
        updateMenuPlaces(screenIndex) {
            if (this.screens[screenIndex].menuPlace === this.screens[screenIndex].menuTitle) {
                this.screens[screenIndex].menuPlace = '';
            }

            if (this.currentMenuTitle) {
                const index = this.menuPlaces.findIndex(place => place.value === this.currentMenuTitle);
                if (index !== -1) {
                    this.menuPlaces.splice(index, 1);
                }
            }

            this.currentMenuTitle = this.screens[screenIndex].menuTitle;

            this.getMenuPlaces();
        },
        addScreen() {
            const allFieldSections = document.querySelectorAll('.input-section');
            allFieldSections.forEach(section => {
                section.style.display = 'none';
            });

            this.screens.push({
                screenType: 'list-edit',
                modelName: '',
                screenTitle: '',
                menuTitle: '',
                fields: [],
                relations: [],
                menuPlace: '',
                menuGroup: 'no',
                needMigration: 'yes',
                currentMenuTitle: null,
            });
        },
        removeScreen(screenIndex) {
            this.screens.splice(screenIndex, 1);
        },
        addField(screenIndex) {
            // Скрываем все открытые аккордеоны
            const allFieldSections = document.querySelectorAll('.field-section');
            allFieldSections.forEach(section => {
                section.style.display = 'none';
            });

            // Добавляем новое поле
            this.screens[screenIndex].fields.push({name: '', code: '', type: ''});
        },
        removeField(screenIndex, relationIndex) {
            this.screens[screenIndex].fields.splice(relationIndex, 1);
        },
        addRelation(screenIndex) {
            // Скрываем все открытые аккордеоны
            const allFieldSections = document.querySelectorAll('.field-section');
            allFieldSections.forEach(section => {
                section.style.display = 'none';
            });

            console.log(this.screens)
            // Добавляем новую связь
            this.screens[screenIndex].relations.push({type: '', relatedModel: ''});
        },
        removeRelation(screenIndex, relationIndex) {
            this.screens[screenIndex].relations.splice(relationIndex, 1);
        },
        toggleAccordion(event) {
            const fieldSection = event.currentTarget.nextElementSibling;
            fieldSection.style.display = fieldSection.style.display === 'none' ? '' : 'none';
        },
        getMenuPlaces() {
            axios.get('/ajax/get-menu-places')
                .then(response => {
                    this.menuPlaces = response.data;

                    // Обновляем menuPlaces в каждом экране
                    this.screens.forEach((screen, index) => {
                        // Находим индекс нового menuTitle в menuPlaces
                        const insertIndex = this.menuPlaces.findIndex(place => place.value === screen.menuPlace);

                        // Добавляем новый menuTitle в menuPlaces на нужное место
                        if (insertIndex !== -1 && !this.menuPlaces.some(place => place.value === screen.menuTitle)) {
                            this.menuPlaces.splice(insertIndex + 1, 0, {
                                value: screen.menuTitle,
                                label: screen.menuTitle
                            });
                        }
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        generate(e) {
            e.preventDefault()
            try {
                // Сбор всех данных из формы
                const formData = this.screens;

                // Отправка данных на сервер для обработки
                axios.post('/ajax/generate-screens', formData)
                    .then(response => {
                        // Обработка успешного ответа
                        console.log(response.data);
                    })
                    .catch(error => {
                        // Обработка ошибок валидации
                        this.errors = error.response.data.errors;
                        // Обработка других ошибок
                        console.error(error);
                    });
            } catch (error) {
                console.error(error);
            }
            return false;
        }
    }
}
</script>


<style scoped>
* {
    user-select: none;
}

.errors {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

label {
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
}

.errors-list {
    margin-bottom: 3px;
}

.error-item {
    display: block;
    color: #721c24;
    font-size: 14px;
    font-weight: 500;
}

.screen-generator {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
}

.input-section {
    margin-bottom: 20px;
}

label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 5px;
}

input,
select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: #4caf50;
    color: white;
    height: auto;
    padding: 14px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    border: 1px solid #4caf50;
}

button:hover {
    background-color: #fff;
    color: #4caf50;
}

.add-button {
    width: 100%;
    margin-bottom: 10px;
}

.remove-btn {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 3.5px 7.5px 4.5px 8.5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: unset;
    &:hover {
        background-color: #d32f2f;
    }
}

.fields-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
}

.field-section {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 10px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px;
    align-items: center;
    background: #e6e6e6;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
        background: #f1f1f1;
    }
}

.fields-data {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.top-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;

    input {
        margin-right: 5px;
        position: relative;
        top: 2px;

    }
}

.top-side label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    flex-basis: unset;
    flex-grow: unset;
    position: relative;
}

.middle-side, .bottom-side {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

label {
    flex-grow: 1;
    flex-basis: 200px;
}

.field-section input[type="checkbox"] {
    width: auto;
}


.field-section input,
.field-section select {
    margin-right: 10px;
    margin-bottom: 10px;
}

.buttons-container {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
}
.menu-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.need-migration {
    width: 100%;
}
</style>
