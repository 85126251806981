import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// text banner
gsap.registerPlugin(ScrollTrigger);
const text = document.querySelectorAll('.banner h1');

gsap.timeline({
    scrollTrigger: {
        trigger: '.banner',
        start: 'top 90%',
        toggleActions: 'play none none none'
    }
})
    .to(text, {
        opacity: 1,
        y: 0,
        ease: "back.out(1)",
        duration: 2
    });


// window.addEventListener('load', () => {
//     const header = document.querySelector('.banner h1');
//     const container = document.querySelector('.banner-content');
//     const containerInner = document.querySelector('.banner .container');
//     const computedStyleContainer = window.getComputedStyle(containerInner);
//     const paddingLeft = parseFloat(computedStyleContainer.paddingLeft);
//     const paddingRight = parseFloat(computedStyleContainer.paddingRight);
//
//     const containerWidth = container.offsetWidth - (paddingLeft + paddingRight);
//
//     const words = header.innerText.split(' ');
//
//     let lines = [];
//     let currentLine = '';
//
//     const tempDiv = document.createElement('div');
//     tempDiv.style.position = 'absolute';
//     tempDiv.style.visibility = 'hidden';
//     tempDiv.style.whiteSpace = 'normal';
//     tempDiv.style.wordBreak = 'break-word';
//     document.body.appendChild(tempDiv);
//
//     const computedStyle = window.getComputedStyle(header);
//     Object.assign(tempDiv.style, {
//         font: computedStyle.font,
//         fontSize: computedStyle.fontSize,
//         fontWeight: computedStyle.fontWeight,
//         lineHeight: computedStyle.lineHeight,
//         fontFamily: computedStyle.fontFamily,
//         letterSpacing: computedStyle.letterSpacing,
//         textTransform: computedStyle.textTransform,
//     });
//
//     words.forEach(word => {
//         const newLine = currentLine + (currentLine ? ' ' : '') + word;
//         tempDiv.innerText = newLine;
//
//         if (tempDiv.offsetWidth > containerWidth) {
//             lines.push(currentLine.trim());
//             currentLine = word;
//         } else {
//             currentLine = newLine;
//         }
//     });
//
//     if (currentLine) lines.push(currentLine.trim());
//
//     header.innerHTML = '';
//     lines.forEach((line, index) => {
//         const div = document.createElement('div');
//         div.innerText = line;
//         header.appendChild(div);
//
//         gsap.from(div, {
//             opacity: 0,
//             y: 100,
//             duration: 1,
//             delay: index * 0.3,
//             ease: 'power2.out',
//         });
//     });
//
//     document.body.removeChild(tempDiv);
// });

//clients blocks
const clientSection = document.querySelector('.clients');
if (clientSection) {
    document.addEventListener('DOMContentLoaded', () => {
        gsap.registerPlugin(ScrollTrigger);
        const blocks = document.querySelectorAll('.client-block');

        blocks.forEach(block => {
            const img = block.querySelector('.img-wrapper');
            const info = block.querySelector('.info');

            gsap.from(img, {
                y: 370,
                opacity: 0,
                duration: 0.9,
                ease: "back.out(1)",
                scrollTrigger: {
                    trigger: block,
                    start: 'top 70%',
                    toggleActions: 'play none none none'
                }
            });

            gsap.from(info, {
                y: 300,
                opacity: 0,
                duration: 0.9,
                ease: "back.out(1)",
                scrollTrigger: {
                    trigger: block,
                    start: 'top 70%',
                    toggleActions: 'play none none none'
                }
            });
        });
    });
}

//zoom section
const zoomSection = document.querySelector('.zoom')
if(zoomSection){
    document.addEventListener('DOMContentLoaded', () => {
        gsap.registerPlugin(ScrollTrigger);

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.zoom',
                start: 'top 50%',
                toggleActions: 'play none none none'
            }
        });

        timeline
            .from('.zoom .block.yellow', {
                y: 100,
                opacity: 0,
                duration: 0.4,
                ease: 'power2.out',
            })
            .from('.zoom .block.purple', {
                y: 200,
                opacity: 0,
                duration: 0.4,
                ease: 'power2.out',
            })
            .from(['.zoom .block.red', '.zoom .block.blue'], {
                y: 200,
                opacity: 0,
                duration: 0.4,
                ease: 'power2.out',
            })
            .from(['.zoom .block.green', '.zoom .block.gray'], {
                y: 200,
                opacity: 0,
                duration: 0.4,
                ease: 'power2.out',
            });
    });
}

//stickers
const stickersSection = document.querySelector('.stickers')
if (stickersSection) {
    document.addEventListener('DOMContentLoaded', () => {
        gsap.registerPlugin(ScrollTrigger);

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.stickers',
                start: 'top 50%',
                toggleActions: 'play none none none'
            }
        });

        timeline
            .from(['#sticker-2', '#sticker-green', '#sticker-yellow'], {
                y: 100,
                opacity: 0,
                duration: 0.6,
                ease: 'power2.out',
            })
            .from(['#sticker-1', '#sticker-red'], {
                y: 100,
                opacity: 0,
                duration: 0.6,
                ease: 'power2.out',
            }, "-=0.3")
            .from(['#sticker-3', '#sticker-blue'], {
                y: 100,
                opacity: 0,
                duration: 0.6,
                ease: 'power2.out',
            }, "-=0.3");
    });
}

// work horizontal scroll
gsap.registerPlugin(ScrollTrigger);
function initializeHorizontalScroll() {
    const header = document.querySelector('.header');
    const horizontalScrollSection = document.querySelector('.horizontal-scroll');
    const horizontalScrollCards = document.querySelector('.horizontal-scroll-cards');
    const pinSpacer = document.querySelector('.pin-spacer');
    const container = document.querySelector('.horizontal-scroll .container');

    if (!header || !horizontalScrollSection || !horizontalScrollCards || !pinSpacer || !container) {
        console.warn('Не удалось инициализировать горизонтальный скролл: отсутствуют необходимые элементы.');
        return;
    }

    const containerWidth = container.offsetWidth;

    const getHeaderHeight = () => {
        let additionalHeight = 32;
        if (window.matchMedia("(max-width: 1440px)").matches) {
            additionalHeight = 16;
        }
        return header.offsetHeight + additionalHeight;
    };

    const headerHeight = getHeaderHeight();

    ScrollTrigger.create({
        trigger: horizontalScrollSection,
        start: () => `top-=${headerHeight} top`,
        end: () => `+=${horizontalScrollCards.scrollWidth - containerWidth + headerHeight + 32 * 2}`,
        pin: pinSpacer,
        pinSpacing: true,
        scrub: true,
    });

    gsap.to(horizontalScrollCards, {
        x: () => -(horizontalScrollCards.scrollWidth - containerWidth) + 'px',
        ease: 'none',
        scrollTrigger: {
            trigger: horizontalScrollSection,
            start: () => `top+=${headerHeight} top`,
            end: () => `+=${horizontalScrollCards.scrollWidth - containerWidth}`,
            scrub: true,
        }
    });
}

function checkVueInitialization() {
    const contactUsForm = document.querySelector('.contact-us');
    if (!contactUsForm) {
        initializeHorizontalScroll();
        return;
    }
    if (document.querySelectorAll('.contact-us .landing-form-form').length > 0) {
        initializeHorizontalScroll();
    } else {
        setTimeout(checkVueInitialization, 100);
    }
}
document.addEventListener('DOMContentLoaded', () => {
    checkVueInitialization();
});


